import React, { Component } from 'react'
import { RichText } from 'prismic-reactjs'

const ExpandButton = ({ toggle, expanded }) =>
  <div onClick={toggle}>
    {expanded ? 'CLOSE' : 'MORE'}
    <style jsx>{`
      div {
        font-size: 1rem;
        border: 1px solid #fff;
        background-color: #000;
        color: #fff;
        display: inline;
        font-weight: 600;
        border-radius: 4rem;
        margin-left: 1rem;
        font-family: 'Roobert';
        padding: 0.5rem 1.25rem;
        margin-top: 0.25rem;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        vertical-align: super;
      }

      div:hover {
        background-color: #fff;
        color: #000;
      }
    `}</style>
  </div>

export default class AboutText extends Component {
  state = {
    expanded: false
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render () {
    const { expanded } = this.state
    const { homepage } = this.props
    const { intro_text = '', intro_text_expanded = '' } = homepage
    return (
      <div className={`landing-text expanded-${expanded}`}>
        <div className='regular-text'>
          {RichText.render(intro_text, false)}
        </div>
        {expanded && <div className='regular-text-expanded'>
          {RichText.render(intro_text_expanded, false)}
        </div>}

        <ExpandButton toggle={this.toggleExpand} expanded={expanded} />

        <style jsx global>{`
          .landing-text {
            padding-right: 2rem;
            color: #fff;
            mix-blend-mode: difference;
            z-index: 4;
            position: relative;

          }

          .landing-text p, .landing-text h1, .landing-text h2, .landing-text h3, .landing-text h4, .landing-text h5, .landing-text h6 {
            font-size: 4rem;
            margin: 0;
            margin-bottom: 2rem;
            letter-spacing: -0.02em;
            font-weight: 200;
            line-height: 1;
            font-family: 'Canela';
            text-transform: unset;
          }
          @media (max-width: 750px) {
             .landing-text p, .landing-text h1, .landing-text h2, .landing-text h3, .landing-text h4, .landing-text h5, .landing-text h6  {
               font-size: 3.2rem;
             }
          }

          .landing-text a {
            position: relative;
            display: inline;
            color: #fff;
          }
          .landing-text a:after {
            position: absolute;
            content: ' ';
            left: 0;
            bottom: 0.05em;
            width: 100%;
            border-bottom: 1px solid #fff;
          }
          @media (max-width: 750px) {
            .landing-text a {border-bottom: 1px solid white;} 
          }

          .regular-text-expanded p {
            margin-left: 0;
          }
          
          .expanded-true {
            margin-left: 2rem;
          }
          .expanded-true .regular-text {
            margin-left: -2rem;
          }

          .landing-text .regular-text-expanded {
            display: inline-block;
          }

          .expanded-true .regular-text-expanded, .expanded-true .regular-text-expanded p:last-of-type, .expanded-true .regular-text-expanded div {display: inline}

          .expanded-true .regular-text, .expanded-true .regular-text p, .expanded-true .regular-text div, .expanded-true .regular-text {display: inline-block}

          .regular-text-expanded {
            // margin-left: 2rem;
          }

          .regular-text p, .regular-text div, .regular-text {
            display: inline;
          }

          .landing-text span {display: inline}

          .landing-text p {
            // margin-bottom: 4rem;
          }
        `}</style>
      </div>
    )
  }
}

AboutText.defaultProps = {
  homepage: {}
}
