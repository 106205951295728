import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Head from '../Head'
import PostsHeader from './PostsHeader'
import { P } from '../Typography'
import Logo from '../Logo'

export default class Header extends Component {
  render() {
    const { posts, url } = this.props
    return (
      <>
        <Head />
        <header>
          <div className='left-header'>
            <div className='logo'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>

            <div className='mr3'>
              <P>
                1635 N. SPRING ST #2 <br />
                LOS ANGELES CA 90012
              </P>
            </div>
            <div className='mr3'>
              <P>
                <a href='mailto:contact@hpla.co'>contact@hpla.co</a> <br />
                <a target='_blank' rel='noopener noreferrer' href='https://instagram.com/hp.la'>@hp.la</a> <br />
              </P>
            </div>
          </div>

          <div className='header-right'>
            {posts && <PostsHeader url={url} posts={posts} />}
          </div>

          <style jsx global>{`
            .left-header p {margin-bottom: 0}
             header .logo img {width: 6.5rem; margin-right: 2rem; vertical-align: middle; cursor: pointer}
          `}</style>

          <style jsx>{`
            .logo img {width: 6.5rem; margin-right: 2rem; vertical-align: middle; cursor: pointer}
          
            header {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 10;
              background-color: #fff;
              display: flex;
              flex-wrap: wrap;
              align-items: top;
              justify-content: space-between;
              padding: 1rem 1rem;
              padding-right: 0;
            }

            .left-header {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding-bottom: 1rem;
            }

            .mr3 {margin-right: 3rem}

            .logo a {
              margin-right: 3rem;
              font-family: 'Helvetica Neue';
              cursor: pointer;
              display: inline-block;
              font-size: 1.4rem;
              border: 1px solid black;
              border-radius: 2rem;
              padding: 0.25rem 1.25rem;
              text-decoration: none;
              color: black;
            }

            @media (max-width: 750px) {
              header {
                position: relative;
                padding: 1rem;
              }
              .mr3{margin-right: 1rem;}
              .logo {width: 100%; margin-bottom: 1rem;}
              .header-right{width: 100%}
              .left-header {padding-bottom: 0;}

              .header-right {display: none}
            }
            
          `}
          </style>
        </header>
      </>
    )
  }
}
