import React, { PureComponent } from 'react'

import AboutText from './AboutText'
import Posts from './Posts'

export default class Landing extends PureComponent {
  render() {
    const { homepage, posts, hide, show, hidden, router } = this.props
    return (
      <div className='main-header'>
        <AboutText
          hidden={hidden}
          homepage={homepage.data} />
        <Posts
          router={router}
          hide={hide}
          show={show}
          posts={posts} />

        <style jsx>{`
          .main-header {
            max-width: 70rem;
            margin-left: 9.5rem;
            margin-top: 2rem;
          }

          @media (min-width: 1300px) {
            .main-header {
              max-width: unset;
              padding-right: 6rem;
              // margin-left: auto;
              // margin-right: auto;
            }
          }

          @media (max-width: 750px) {
            .main-header{
              margin-left: 0;
              margin-top: 1rem;
              padding-left: 1rem;
            }
          }
        `}</style>
      </div>
    )
  }
}