import React, { PureComponent } from 'react'
import { getRandomInt } from '../../util'
import { Redirect, Link } from 'react-router-dom'

import Image from '../Image'

class Post extends PureComponent {
  state = {
    mobile: false,
    redirect: false,
    postId: false,
    selectedImageVisible: false
  }

  getRandomPosition = active => {
    if (this.state.mobile) {
      return {
        top: `0%`,
        left: `0%`
      }
    } else {
      return {
        top: `${getRandomInt(2, 55)}%`,
        left: `${getRandomInt(2, 70)}%`
      }
    }
  }

  componentDidMount() {
    if (window.innerWidth < 750) {
      this.setState({ mobile: true })
    }
  }

  selectThis = () => {
    this.props.select(this.props.uid)
  }


  delayRoute = () => {
    this.selectThis()

    this.setState({ selectedImageVisible: true })
    setTimeout(() => {
      this.setState({ selectedImageVisible: false })
    }, 1000)

    setTimeout(() => {
      this.setState({ redirect: true, postId: this.props.uid })
    }, 1300)
  }

  render() {
    const { mobile, redirect, postId, selectedImageVisible } = this.state
    const { data, uid, selected, deselect  } = this.props
    const internalSelected = uid === selected

    if (redirect && postId) {
      return <Redirect to={`/${uid}`} />
    }

    return (
      <>
        <div className={`section ${internalSelected && 'selected'} ${!internalSelected && selected ? 'hidden' : ''}`}>
          {!mobile ?
            <Link to={`/${uid}`}>
              <span className='a'

                onTouchStart={this.selectThis}
                onTouchEnd={deselect}

                onMouseEnter={this.selectThis}
                onMouseLeave={deselect}>
                <div className='order-num'>
                  <p>{data.order_number}</p>
                </div>

                <div className='text'>
                  <p>{data.client_name[0].text}</p>
                  <p className='serif'>{data.project_title[0].text}</p>
                </div>
              </span>
            </Link>
            : <div className={`mobile-selected-${internalSelected}`}>
              <span className='a' onClick={this.delayRoute}>
                <div className='order-num'>
                  <p>{data.order_number}</p>
                </div>

                <div className='text'>
                  <p>{data.client_name[0].text}</p>
                  <p className='serif'>{data.project_title[0].text}</p>
                </div>
              </span>
            </div>
          }
        </div>

        {internalSelected && <div style={{ ...this.getRandomPosition() }} className={`image selected-image-visible-${selectedImageVisible}`}><Image src={data.preview_image.url} /></div>}
        <style jsx global>{`
          .image img, .image figure {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: 0 0;
          }

          @media (max-width: 750px) {
            .image img, .image figure {
              object-fit: cover;
            }
            .image figure, .image img {height: 100%; width: 100%; object-fit: cover; object-position: 50% 50%}

            .image {
              height: 100vh; width: 100vw;
              transition: 0.3s ease-out opacity;
            }

            .image.selected-image-visible-true {
              opacity: 1;
            }

            .image.selected-image-visible-false {
              opacity: 0 !important;
            }

            .mobile-selected-true .text p {
              color: #fff;
            }
          }

        `}</style>
        <style jsx>{`
          a{display: block;}
        
          .section {
            width: 33.33%;
            padding-bottom: 3rem;
            position: relative;
            z-index: 2;
            mix-blend-mode: difference;
            cursor: pointer;
            // transition: 0.3s ease-in-out opacity;
          }

          .serif {
            font-family: 'Canela';
            font-size: 1.4rem;
            text-transform: unset;
            hyphens: auto
          }

          .text {
            max-width: 18rem; hyphens: auto;
            position: relative;
            flex: 1;
            padding-right: 2rem;
            margin-top: 0.56rem;
          }

          .text p {
            color: #fff;
            line-height: 1.2;
          }

          .a{display:flex}

          .image {
            pointer-events: none;
            position: fixed;
            overflow: hidden;
            z-index: 1;
            width: 60vmin;
            height: 45vmax;
            opacity: 0.9
          }

          .hidden.selected .order-num {
            background-color: #000;
            color: #fff;
          }
          .selected .order-num {
            background-color: #fff;
            color: #000;
          }
          .order-num {
            height: 2rem;
            width: 2rem;
            border: 1px solid #fff;
            color: #fff;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-right: 0.5rem;
          }
          .order-num p {
            margin-bottom: 0.1rem;line-height: 1;
          }
          
          .order-num, a {
            display: flex;
            flex-wrap: wrap;
          }

          a {
            width: 100%;
          }

          img {
            display: none;
          }

          @media (max-width: 750px) {
            .section {
              width: 50%;
              mix-blend-mode: unset;
            }

            .order-num {
              color: #000;
              border: 1px solid #000;
            }
            .selected .order-num, .a:hover .order-num {
              background-color: #000;
              color: #fff;
            }
            .text p {
              color: #000;
            }

            .image {
              height: 100vh;
              width: 100vw;
              z-index: 9999999;
              point-events: none;
            }
          }
        `}</style>
      </>
    )
  }
}

export default class Posts extends PureComponent {
  state = {
    selected: false
  }

  componentDidUpdate() {

  }

  select = (uid) => {
    this.setState({ selected: uid })
    this.props.hide()
  }

  deselect = () => {
    this.setState({ selected: false })
    this.props.show()
  }

  pushRouter = (route) => {
    this.props.router.push(route)
  }

  render() {
    const { selected } = this.state
    const { posts } = this.props
    return (
      <section>
        {posts.map((post, index) => {
          return <Post
            pushRouter={this.pushRouter}
            select={this.select}
            deselect={this.deselect}
            selected={selected}
            key={index}
            {...post} />
        })}

        <style jsx>{`
          section {
            padding: 4rem 0rem;
            display: flex;
            flex-wrap: wrap;
          }
        `}</style>
      </section>
    )
  }
}

Posts.defaultProps = {
  posts: []
}
