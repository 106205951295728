import React from 'react'
import { RichText } from 'prismic-reactjs'

const TitleText = ({
  text,
  clientName,
  projectTitle,
  orderNumber
}) => {

  return (
    <div className={` text-title-section`}>
      <article>
        <div>
          <div className='num'><span>{orderNumber}</span></div>
          <div className='header'>
            <div className='client'>{clientName[0].text}</div>
            <div className='project'>{projectTitle[0].text}</div>
          </div>
        </div>
        <div className={`title-text-content ${text.length > 10 ? 'two-column' : ''}`}>
          {RichText.render(text, false)}
        </div>
      </article>
      <style jsx global>{`
      // .text-title-section span {width: 100%; display: block}
      .text-title-section p:empty {margin-bottom: 1rem;}
      .text-title-section p {
        font-size: 1.3vmin;
        margin-bottom: 0.75rem;
      }
      @media (max-width: 750px) {
        .text-title-section p {
          font-size: 0.86rem;
        }
        .text-title-section {margin-left: 0.5rem !important;}
      }
    `}</style>
      <style jsx>{`
      .header {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-top: 0rem;
      }


       
      .client {
        font-family: 'Roobert';
        font-size: 1.4rem;
        padding-right: 0.5rem;
        text-transform: uppercase;
      }
      .project {
        font-family: 'Canela';
        font-size: 3rem;
        font-weight: 200;
        margin-bottom: 2rem;
      }

      .num {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: 1px solid black;
        cursor: pointer;
        font-family: 'Roobert';
      }
      .num span {
        margin-bottom: 0.1rem
      }

      .two-column {
        column-count: 2;
        padding-right: 10vh
      }

      .text-title-section {
        height: calc(100vh - 6rem);
        margin-left: 1rem;
        margin-top: 1rem;
      }

      p:empty {
        margin-bottom: 1rem;
      }

      article {
        width: 80vh;
        padding-left: 15vh;
      }

      .text-title-section {
        padding-top: 8vmin;
      }

      @media (max-width: 750px) {

          .two-column {
            // column-count: 1;
            padding-right: 0vh
          }

        article {
          width: 100%;
          padding-left: 0rem;
          padding-bottom: 4rem;
        }
        .text-title-section {
          height: auto;
          padding-top: 0;
        }
      }
    `}</style>
    </div>
  )
}


export default TitleText