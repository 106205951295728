import React from 'react'

export const P = ({ children }) =>
  <span>
    <p>{children}</p>
    <style jsx global>{`
      body {
        font-family:'Roobert', 'Helvetica';
      }
      p {
        font-size: 0.86rem;
        font-family: 'Roobert', 'Helvetica';
        text-transform: uppercase;
        line-height: 1.3;
        letter-spacing: 0.06em;
        margin: 0;
        margin-bottom: 0.25rem;
        display: block;
      }
      p.serif {
        letter-spacing: 0;
      }

      span {
        display: block;
      }
    `}</style>
  </span>