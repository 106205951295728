import React, { PureComponent } from 'react'
import PlayIcon from './PlayIcon'

class Embed extends PureComponent {
  state = {
    active: false
  }

  toggleState = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    const { coverPhoto, html } = this.props
    const { active } = this.state
    const updatedHtml = html.replace('app_id=122963', 'app_id=122963&autoplay=1')

    return (
      <div className='video-provider'>
        <section>
          {!active
            ? <>
              <PlayIcon />
              <img alt='' onClick={this.toggleState} src={coverPhoto.url} />
            </>
            : <div className='iframe' dangerouslySetInnerHTML={{ __html: updatedHtml }} />
          }
        </section>

        <style jsx global>{`
          iframe {
            position: absolute;
            top: 0;left: 0;
            height: 100%;
            width: 100%;
          }

          .img-container {
            height: 0;
            // padding-bottom: 56%;
            overflow: hidden;
            position: relative;
          }
          
        `}</style>
        <style jsx>{`
          img, .iframe {
            margin-right: 0.5rem;
            width: 100vh;
            cursor: pointer;
            position: relative;
          }
          @media (max-width: 750px) {
            img, .iframe {
              margin-right: 0rem;
              margin-left: 0rem;
              padding: 0.5rem;
            }
          }

          .iframe {
            height: 0;
            padding-bottom: 56%;
            background-color: #000;
          }

          img {
            padding: 0.5rem;
            box-sizing: border-box;
          }

          section {
            height: calc(100vh - 6rem);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }

          .video-provider {
            position: relative;
          }

          @media (max-width: 750px) {
            .video-provider {width: 100%; display: block;}

            section {
              height: unset;
              width: 100%;
            }
            img, .iframe {
              width: 100%;
            }
          }
        `}</style>
      </div>
    )
  }
}

export default Embed
