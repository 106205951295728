import Prismic from 'prismic-javascript'

const apiEndpoint = 'https://hpla.prismic.io/api/v2'

const getPostsAPI = async params => {
  try {
    const API = await Prismic.api(apiEndpoint)

    const res = await API.query(
      Prismic.Predicates.at('document.type', 'post'),
      {
        orderings: '[my.post.order_number]',
        pageSize: 100,
        ...params
      }
    )

    console.log(res, 'res!!')
    const homepage = await API.query(
      Prismic.Predicates.at('document.type', 'page')
    )
    return {
      posts: res.results,
      homepage: homepage.results
    }

  } catch (err) {
    console.log(err, 'err!!')
    return err
  }
}

const getPostAPI = async slug => {
  try {
    const API = await Prismic.api(apiEndpoint)
    const res = await API.query(
      Prismic.Predicates.at('my.post.uid', slug)
    )
    const allPosts = await API.query(
      Prismic.Predicates.at('document.type', 'post'),
      {
        orderings: '[my.post.order_number]'
      }
    )

    return {
      post: res.results[0],
      posts: allPosts.results
    }
  } catch (err) {
    console.error(err, 'err')
    return err
  }
}

export { getPostsAPI, getPostAPI }
