import React, { PureComponent } from 'react'

class Image extends PureComponent {
  state = {
    loaded: false,
    entered: false
  }

  constructor() {
    super()
    this.element = React.createRef()
    this.observer = null
  }

  componentDidMount() {
    if (this.props.i === 0 || this.props.i === 1 || this.props.i === 2) {
      this.setState({ entered: true })
    }

    this.observer = new IntersectionObserver(
      entries => {
        const entry = entries[0]
        if (entry.isIntersecting) this.setState({ entered: true })
      }, {
        threshold: [0, 1.0]
      }
    )

    this.observer.observe(this.element.current)
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  onLoadEffect = (img) => {
    this.setState({ loaded: true })
  }

  render() {
    const { src, dimensions, spacing } = this.props
    let mobileImage = false
    let mobileX = false

    if (this.props.image['mobile-image']) {
      mobileImage = this.props.image['mobile-image']
      mobileX = (mobileImage.dimensions.height / mobileImage.dimensions.width)
    }

    const r = (dimensions.width / dimensions.height)
    const x = (dimensions.height / dimensions.width)

    
    
    const { loaded, entered } = this.state
    return (
      <div>
        <section ref={this.element} className='img-container'>
          {entered ? 
            <picture onLoad={this.onLoadEffect}>
              {mobileImage && <source media='(max-width: 700px)' srcset={mobileImage.url}></source>}
              <img alt='' src={src} />
            </picture>
             : <div className='loading' />}
        </section>
        <style jsx>{`
        div {
          padding: ${spacing === 'none' ? '0 0' : '0rem 0.5rem'}        
        }

        section {
          width: calc(${r * 100}vh - 6rem);
          position: relative;
          // background-color: #eaeaea; 
        }
        section, .loading {
          height: calc(100vh - 5rem);
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          opacity: ${loaded ? '1' : '0'};
          transition: 0.4s ease-out opacity;
          width: 100%;
        }
        img {vertical-align: middle}


        @media (max-width: 750px) {
          section {
            width: auto;
            height: 0 !important;
            padding-bottom: ${mobileX ? mobileX * 100 : x * 100}%;
          }
          img {
            position: relative;
            height: auto;
            width: 100%;
          }
          div {
            width:100%;
            padding: ${spacing === 'none' ? '0 0' : '0.5rem 0.5rem'}  
          }
        }

      `}</style>
      </div>
    )
  }
}

export default Image
