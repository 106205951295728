import React from 'react'
import { RichText } from 'prismic-reactjs'

const Text = ({ text }) =>
  <div className='text-section'>
    <article>
      {RichText.render(text, false)}
    </article>
    <style jsx global>{`
      .text-section span {width: 100%; display: block}
      .text-section p:empty {margin-bottom: 1rem;}
    `}</style>
    <style jsx>{`
      div {
        height: calc(100vh - 6rem);
        margin-left: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      article {
        width: 80vh;
        padding-left: 20vh;
      }

      @media (max-width: 750px) {
        div {
          height: auto;
          padding: 4rem 0;
        }
        article {
          width: 100%;
          padding-left: 1rem;
        }
      }
    `}</style>
  </div>

export default Text
