import React, { Component } from 'react'

import Layout from '../components/Layout'
import Post from '../components/Post'

export default class Posts extends Component {
  state = {
    post: undefined
  }

  componentDidMount () {
    this.findPost()
  }

  componentWillReceiveProps (props) {
    setTimeout(this.findPost, 20)
  }

  findPost = () => {
    const foundPost = this.props.posts.filter(this.isPost)
    this.setState({ post: foundPost[0] })
  }

  isPost = post => {
    return post.uid === this.props.match.params[0]
  }

  render () { 
    const { post } = this.state
    const { posts, match } = this.props
    const url = match.params[0]

    return (
      <Layout url={url} posts={posts} type='post'>
        {post && <Post key={url} {...post} />}
      </Layout>
    )
  }
}


Posts.defaultProps = {
  posts: []
}