import React from 'react'
import { Link } from 'react-router-dom'

const Circle = ({ number, selected }) =>
  <span className={`${selected} a`}>
    <span>
      {number}
    </span>
    <style jsx>{`
      .a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          border: 1px solid black;
          margin-right: 1rem;
          cursor: pointer;
          font-family: 'Roobert';
        }

        span {
          margin-bottom: 0.1rem;line-height: 1;
        }
        
        .selected {opacity: 0 }
        .a:hover {
          background-color: #000;
          color: #fff;
        }

        @media (max-width: 1000px) {
          // .a {height: 1.5rem; width: 1.5rem; font-size: 0.8rem; line-height: 1.8;padding-top: 0.1rem}
        }

        @media (max-width: 750px) {
          .a{margin-left: auto; margin-right: auto; grow: 1;}
          span:first-of-type a {
            margin-left: 0;
          }
          .a{margin-top: 1rem;}
        }
    `}</style>
  </span>

const Route = ({ data, uid, url }) => {
  const currentRoute = uid === url ? 'selected' : 'nun'
  return (
    <span className='top-parent-a'>
      <Link to={`/${uid}`}>
        <span><Circle number={data.order_number} selected={currentRoute} /></span>
      </Link>

    </span>
  )
}

export default ({ posts, url }) =>
  <div id='posts-header'>
    <span className='top-parent-a'>
      <Link to='/'>
        <span><Circle number='0' /></span>
      </Link>
    </span>
    {posts.map((post, i) => <Route url={url} key={i} {...post} />)}
      <style jsx global>{`
        #posts-header {display: none}
        .type-post header #posts-header {display: flex}

        @media(max-width: 750px) {
          // .top-parent-a {flex-grow: 1;}
          .top-parent-a {flex-basis: 10%}
          #posts-header { justify-content: flex-start; display: flex; padding: 0rem 0.5rem; padding-bottom: 1rem;}
          header #posts-header {display: none;}
        } 
      `}</style>
    <style jsx>{`
      div {
        display: flex;
        flex-wrap: wrap;
      }

      @media (max-width: 1000px) {
        div {font-size: 0.9rem}
      }
      @media (max-width: 750px) {
        div {
          background-color: #fff;
          position: sticky;
          padding-bottom: 1rem;
          top: 0; left: 0;
          z-index:999;
          width: 100%;
          justify-content:space-between;
        }
      }
    `}</style>
  </div>
