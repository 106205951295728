import React from 'react'

const PlayIcon = () =>
  <>
    <svg viewBox="0 0 24 24"><path d="M8 5v14l11-7z" /><path d="M0 0h24v24H0z" fill="none" /></svg>
    <style jsx>{`
      svg {
        width: 4rem;
        position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        fill: #fff;
        pointer-events: none;
      }
    `}</style>
  </>

export default PlayIcon