import React from 'react'

export default ({ src }) =>
  <figure>
    <img alt='' src={src} />
    <style jsx>{`
      figure, img {
        padding: 0; margin: 0;
        width: 100%;
        vertical-align: bottom;
      }
    `}</style>
  </figure>