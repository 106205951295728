import React, { Component, PureComponent } from 'react'

import Head from 'react-helmet'
import 'intersection-observer'

import Image from './Image'
import Embed from './Embed'
import Text from './Text'
import TitleText from './TitleText'

class Content extends Component {
  render() {
    const { data } = this.props
    return (
      <>
        {data.body.map((block, i) => {
          if (block.slice_type === 'image') {
            return <Image
              i={i}
              image={block.primary.image}
              dimensions={block.primary.image.dimensions}
              spacing={block.primary.image_spacing}
              src={block.primary.image.url}
              key={i} />
          } else if (block.slice_type === 'video_embed_section') {
            return <Embed
              coverPhoto={block.primary.cover_photo}
              thumbnail={block.primary.embed.thumbnail_url_with_play_button}
              html={block.primary.embed.html}
              key={i} />
          } else if (block.slice_type === 'text_section') {
            return <Text key={i} text={block.primary.text} />
          } else if (block.slice_type === 'title_text_section') {
            return <TitleText
              clientName={data.client_name}
              projectTitle={data.project_title}
              key={i}
              orderNumber={data.order_number}
              text={block.primary.text} />
          } else {
            return false
          }
        })}
      </>
    )
  }
}

class Marker extends PureComponent {
  state = {
    entered: false
  }

  constructor() {
    super()
    this.element = React.createRef()
    this.observer = null
  }

  componentDidMount() {
    // reset scroll because on page navigations the pages jump around
    this.props.resetScroll()

    this.observer = new IntersectionObserver(
      entries => {
        const entry = entries[0]
        // check to see the absolute positioned div thats 100vw away is in viewport 
        // to initiate infinite scroll
        if (entry.isIntersecting) this.props.resetScroll()
      },
      {
        threshold: [0, 1.0]
      }
    )

    this.observer.observe(this.element.current, ' current...')
  }

  componentWillUnmount () {
    this.observer.disconnect()
  }

  render() {
    return (
      <div>
        <span ref={this.element}>dios</span>
        <style jsx>{`
          div {
            position:relative;
            height: 100vh;
            width:300vh;
            // background-color: red;
          }

          span {
            position: absolute;
            top: 0;
            opacity: 0;
            display: block;
            left: calc(100vw - 0.25rem);
            // background-color: salmon;
          }
        `}</style>
      </div>
    )
  }
}

export default class Post extends PureComponent {
  state = {
    mobile: false,
  }


  componentDidMount() {

    if (window.innerWidth > 750) {
      this.setState({ mobile: true })
    }
  }

  render() {
    const { mobile } = this.state

    const { data, resetScroll } = this.props
    console.log(data,' DATA')
    return (
      <article>
        <Head>
          <title>{`${data.project_title[0].text} - ${data.client_name[0].text} | HPLA`}</title>
        </Head>

        <Content data={data} />

        {mobile && <>
          <Marker resetScroll={resetScroll} />
          <Content data={data} />
        </>}
        <style jsx>{`
          article {
            margin-left: 0rem;
            width: 100%;
            height: calc(100vh - 4rem);
            display: flex;
            flex-direction: row;
            flex: 1 0 auto;
          }

          @media (max-width: 750px) {
            article {
              height: unset;
              flex-wrap: wrap;
              padding: 0.5rem;
            }
          }
        `}</style>
      </article>
    )
  }
}

Post.defaultProps = {
  data: {
    body: []
  }
}
