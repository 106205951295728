import React, { Component } from 'react'
import Header from './Header'
import PostsHeader from './Header/PostsHeader'

const ScrollBar = ({ width }) =>
  <div id='scrollBar' style={{ width: width + '%' }}>
    <style jsx>{`
      div {
        height: 1px;
        background: black;
        position:fixed;
        top: 0rem;
        width: 0;
        left: 0;
        z-index: 9999;
        pointer-events: none;
      }
    `}
    </style>
  </div>

export default class Layout extends Component {
  state = {
    everythingHidden: false,
    mobileView: false,
    scrollPos: 0
  }

  constructor(props) {
    super(props)
    this.main = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0,0)
    
    if (this.props.type === 'post' && window.innerWidth > 750) {
      this.main.current.addEventListener('mousewheel', this.handleScroll, true)
      // this.main.current.addEventListener('scroll', this.handleScroll, true)
    }

    if (window.innerWidth < 750) {
      this.setState({ mobileView: true })
    }
  }

  componentWillUnmount() {
    this.main.current.removeEventListener('mousewheel', this.handleScroll)
    // this.main.current.removeEventListener('scroll', this.handleScroll)
  }

  hide = () => {
    this.setState({ everythingHidden: true })
  }

  show = () => {
    this.setState({ everythingHidden: false })
  }

  handleScroll = (e) => {
    this.main.current.scrollLeft += e.deltaY || e.deltaX

    let scroll = this.main.current.scrollLeft / (this.main.current.scrollWidth / 2) * 100
    console.log(scroll, ' scroll')

    this.setState({ scrollPos: scroll })
    e.preventDefault()
  }

  resetScroll = () => {
    if (!this.main.current) return
    this.main.current.scrollLeft = 0
  }

  render() {
    const { children, url, type = 'page', posts = false } = this.props
    const { everythingHidden, mobileView, scrollPos } = this.state

    const childs = React.Children.map(children, child => {
      return React.cloneElement(child,
        {
          resetScroll: this.resetScroll,
          hidden: everythingHidden,
          hide: this.hide,
          show: this.show
        }
      )
    })

    return (
      <article className={`type-${type}`}>
        <Header
          hidden={everythingHidden}
          url={url}
          posts={posts} />

          {mobileView && type === 'post' && <PostsHeader url={url} posts={posts} />}
        <main ref={this.main} id='content'>
          {childs}
        </main>

        <ScrollBar width={scrollPos} />

        <style jsx global>{`
          html, body {padding: 0; margin: 0}
          @media (max-width: 750px) {
            html {font-size: 85%}
          }

          body {
            background-color: #fff;
            -moz-font-feature-settings:"kern" 1; 
            -ms-font-feature-settings:"kern" 1; 
            -o-font-feature-settings:"kern" 1; 
            -webkit-font-feature-settings:"kern" 1; 
            font-feature-settings:"kern" 1;
            -webkit-font-smoothing: antialiased; /* Chrome, Safari */
            -moz-osx-font-smoothing: grayscale; /* Firefox */
            font-kerning: normal;
            font-family: ' ';
            hyphens: auto;
          }

          * {box-sizing: border-box}

          .type-post main {
            height: calc(100vh - 4.5rem);
            margin-top: 4.5rem;
            overflow-y: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
          }

          .type-page main {
            margin-top: 7rem;
            background-color: #fff;
          }

          a {
            text-decoration: none;
            color: #000;
          }

          @media (max-width: 750px) {
            .type-page main {
              margin-top: 2rem;
            }

            .type-post main {
              height: unset;
              margin-top: 0rem;
              overflow-y: visible;
              overflow-x: visible;
              -webkit-overflow-scrolling: auto;
            }
          }
        `}</style>
      </article>

    )
  }
}
