import React, { Component } from 'react'

import Layout from '../components/Layout'
import Landing from '../components/Landing'


class Index extends Component {
  render() {
    const { posts, homepage } = this.props
    return (
      <Layout posts={posts}>
        <Landing posts={posts} homepage={homepage} />
      </Layout>
    )
  }
}

Index.defaultProps = {
  posts: [],
  homepage: []
}

export default Index
