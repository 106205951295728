import React from 'react'
import Head from 'react-helmet'

export default () =>
  <Head>
    <title>HPLA</title>
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <link rel='preload' href='/assets/fonts/Roobert-Regular.woff2' as='font' type='font/woff2' crossOrigin="anonymous" />
    <link rel='preload' href='/assets/fonts/Canela-Thin.otf' as='font' crossOrigin="anonymous" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
    <meta name="msapplication-TileColor" content="#da532c"/>
    <meta name="theme-color" content="#ffffff"/>

    <style>
      {`
          body {
            -webkit-tap-highlight-color: rgba(0,0,0,0);
          }

          @media (min-width: 1700px) {
            html {font-size: 130%}
          }

              @font-face {
                  font-family: 'Roobert';
                  src: url('/assets/fonts/Roobert-Regular.eot');
                  src: url('/assets/fonts/Roobert-Regular.eot?#iefix') format('embedded-opentype'),
                      url('/assets/fonts/Roobert-Regular.woff2') format('woff2'),
                      url('/assets/fonts/Roobert-Regular.woff') format('woff');
                  font-weight: 600;
                  font-style: normal;
                  font-display: swap;
              }
              @font-face {
                  font-family: 'Roobert';
                  src: url('/assets/fonts/Roobert-RegularItalic.eot');
                  src: url('/assets/fonts/Roobert-RegularItalic.eot?#iefix') format('embedded-opentype'),
                      url('/assets/fonts/Roobert-RegularItalic.woff2') format('woff2'),
                      url('/assets/fonts/Roobert-RegularItalic.woff') format('woff');
                  font-weight: 600;
                  font-style: italic;
              }

              @font-face {
                  font-family: 'Canela';
                  src: url('/assets/fonts/Canela-LightItalic.otf') format('opentype');
                  font-weight: 300;
                  font-style: italic;
              }
              @font-face {
                  font-family: 'Canela';
                  src: url('/assets/fonts/Canela-Light.otf') format('opentype');
                  font-weight: 300;
                  font-style: regular;
              }
              @font-face {
                  font-family: 'Canela';
                  src: url('/assets/fonts/Canela-ThinItalic.otf') format('opentype');
                  font-weight: 200;
                  font-style: italic;
              }
              @font-face {
                  font-family: 'Canela';
                  src: url('/assets/fonts/Canela-Thin.otf') format('opentype');
                  font-weight: 200;
                  font-style: regular;
                  font-display: swap;
              }


          @media (max-width: 1300px) {
            html {font-size: 87%}

          }


          
::-webkit-scrollbar {
  background: #fff;
  width: 0px;
}



          .fade-enter.type-page main, .fade-enter-active.type-page main, .fade-exit.type-page main {
            margin-top: 5rem
          }
          .fade-exit-active.type-page main {margin-top: 7rem}

          .fade-exit-active #scrollBar { opacity: 0; width: 100% !important; transition: 0.2s ease-out width, 0.5s ease-out opacity}

          .fade-enter main {
            transform: translateY(-100vh);
            top: 0; left: 0;
            position: absolute;
          }
          .fade-enter-active main {
            opacity: 1;
            transition:transform 400ms ease-in-out, opacity 400ms ease-out;
            transform: translateY(0rem);
            top: 0; left: 0;
            position: absolute;
          }
          .fade-exit main {
            opacity: 1;
            transform: translateY(0rem);
            top: 0; left: 0;
            position: relative;
          }
          .fade-exit-active main {
            transition:transform 400ms ease-in-out, opacity 400ms ease-out;
            transform: translateY(100vh);
          }

          .type-post.fade-enter-active header {opacity: 0;}

          @media (max-width: 750px) {
            .type-post.fade-enter-active main {top: 13rem}
            .type-post.fade-enter-active header {opacity: 1;}

            .fade-exit header, .fade-ext-active header,  .fade-exit #posts-header, .fade-ext-active #posts-header {
              opacity: 0;
            }
          }
`}
    </style>
  </Head>
